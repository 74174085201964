import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async getAll(mac, start, end) {
    let result = await axios.get(`${API_ROUTES.devices.get}/${mac}?start=${start}&end=${end}`);

    return result;
  },

  async find(mac, start, end) {
    let result = await axios.get(`${API_ROUTES.devices.get}/${mac}?start=${start}&end=${end}&from=0&to=1&order=desc`);

    return result;
  },

  async getDevicesByInteval(mac, typeSensor, start, end) {
    let result = await axios.get(`${API_ROUTES.device.get}/${mac}/${typeSensor}?interval=6h&start=${start+' '+'00:00:00'}&end=${end+' '+'00:00:00'}`);

    return result;
  },

};
