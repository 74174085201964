const API_URL = process.env.VUE_APP_API_URL;
const API_VERSION = process.env.VUE_APP_API_VERSION;

const API_ROUTES = {
  devices: {
    get: `${API_URL}/api/${API_VERSION}/devices/search`,
  },
  device: {
    get: `${API_URL}/api/${API_VERSION}/devices`,
  },
}

export { API_ROUTES };